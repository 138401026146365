<!--
 * @Author: liuxia
 * @Date: 2022-08-03 14:00:10
 * @LastEditors: zhangguoliang
 * @LastEditTime: 2022-08-08 10:56:15
-->
<template>
  <div class="wrap">
    <div class="content-wrap">
      <img class="wrap-background" src="@/assets/img/tender-bg.png" />
    </div>
    <div class="content-header">
      <img src="@/assets/img/tender-header.png">
      <span class="text-show">请留下您的联系方式，我们会尽快跟您联系。</span>
    </div>
    <div class="filed">
      <div class="contacts">
        <div class="info">
          <div class="items">
            <label>联系人<span class="symbol">*</span></label>
            <van-field v-model="userName" class="contact" maxlength="10" placeholder="请输入联系人姓名" />
          </div>
          <div class="items">
            <label>联系电话<span class="symbol">*</span></label>
            <van-field
              v-model="userMobile"
              class="contact"
              type="tel"
              maxlength="11"
              placeholder="请输入手机号"
            />
          </div>
          <div class="items">
            <label>备注信息</label>
            <van-field
              v-model="remark"
              class="contact"
              type="textarea"
              maxlength="300"
              placeholder="请输入备注信息"
            />
          </div>
        </div>
        <div class="footer">
          <van-button
            type="info"
            native-type="submit"
            class="apply-submit"
            @click="onSubmit"
            :loading="loading"
            >提交</van-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import { Notify } from 'vant';
import 'vant/lib/button/style';
import * as Api from '@/api/tryOut.ts';
export default Vue.extend({
  data() {
    return {
      show: false,
      userName: '',
      userMobile: '',
      remark: '',
      loading: false,
    };
  },
  created() {
    document.title = '标书代做';
  },
  methods: {
    /**
     * @description: 获取h5执行时所在环境 0 web浏览器 1 抖音 2 微信
     * @param {*}
     * @return {number}
     */
    channel() {
      const ua = window.navigator.userAgent.toLowerCase();
      if (/MicroMessenger/i.test(ua)) {
        return 2;
      }
      if (/aweme/i.test(ua)) {
        return 1;
      }
      return 3;
    },
    onSubmit() {
      if (!this.userName?.trim()) {
        Notify('请输入联系人');
        return;
      }
      if (
        !this.userMobile ||
        !/^[1][3,4,5,7,8,9][0-9]{9}$/.test(this.userMobile.trim())
      ) {
        Notify('请输入正确手机号');
        return;
      }
      this.loading = true;
      const param = {
        userMobile: this.userMobile,
        userName: this.userName,
        // oauthClientId: 'SUB_YUNSUANFANG',
        // oauthClientApplicationName: '云算房',
        // intentionSource: this.channel(),
        remark: this.remark,
      };
      Api.preOrderInfo(param)
        .then((res) => {
          if (res) {
            this.$message.success('提交成功');
            this.reset();
          } else {
            this.$message.error(res.message);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    reset() {
      this.userMobile = '';
      this.userName = '';
      this.remark = '';
    },
  },
});
</script>
<style lang="less" scoped>
label {
  font-size: 18px;
  color: #1d1d1d;
  font-weight: 800;
  display: block;
  margin-bottom: 5px;
}
.wrap {
  width: 100%;
  .content-wrap {
    height: 100vh;
    overflow: hidden;
    position: relative;
    .wrap-background {
      width: 100%;
    }
  }
  .content-header {
    position: absolute;
    top: 30px;
    left: calc((100% - 270px) / 2);
    img {
      width: 270px;
    }
    .text-show {
      display: block;
      width: 240px;
      height: 20px;
      text-align: center;
      line-height: 20px;
      background: #FFFFFF;
      border-radius: 10px;
      font-size: 10px;
      color: #4E5699;
      letter-spacing: 1px;
      margin: 10px 0 0 calc((270px - 240px) / 2);

    }
  }
  .filed {
    position: absolute;
    top: 202px;
    left: 30px;
    background-color: #4e5699;
    padding: 10px 10px;
    border-radius: 8px;
    .items {
      margin-bottom: 22px;
      .symbol {
        color: #FF490F;
      }
    }
    .contacts {
      border-radius: 7px;
      background-color: #ffffff;
    }
    .info {
      padding: 27px 20px 20px;
      .contact {
        border: 1px solid #979797;
        border-radius: 7px;
        width: 100%;
      }
    }
    .footer {
      text-align: center;
      padding: 0 24px 34px 24px;
      .apply-submit {
        width: 249px;
        height: 35px;
        line-height: 35px;
        background: #0052d9;
        border-radius: 2px;
        border: 1px solid rgba(204, 121, 26, 0.01);
        font-size: 21px;
        font-weight: 400;
        bottom: 10px;
      }
    }
  }
}
</style>
<style>
.van-field__control {
  font-size: 18px;
  color: #1d1d1d;
}
.van-cell {
  line-height: 30px;
  padding: 2px 10px;
}
</style>
